<template>
  <div>
    <v-card-title>
      <div>
        <span>{{ $tc('label.anexo', 1)}}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0" style="margin-top: -4px !important;" icon :disabled="!podeCadastrarAnexos" v-on="on" @click="fazerUpload">
              <v-icon>attach_file</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('title.upload_evidencia') }}</span>
        </v-tooltip>
      </div>
    </v-card-title>
    <div class="painel-anexo">
      <v-row>
        <span v-if="!podeVisualizarAnexos && !podeCadastrarAnexos" style="color: darkorange;" class="ma-3 ml-7">
          {{$t('message.upload_anexo_usuario_indisponivel')}}
        </span>
        <list-files
          @ListFiles__arquivos="(files) => $emit('FormAnexoArquivo__qtde', files.length)"
          v-if="podeVisualizarAnexos || podeCadastrarAnexos"
          :habilitar-exclusao="podeCadastrarAnexos"
          ref="listFiles"
          :uri="uri">
        </list-files>
      </v-row>
    </div>
    <upload-file-form
      v-if="podeCadastrarAnexos"
      ref="uploadArquivo"
      :id="!!usuario && usuario.id ? usuario.id : 700"
      :id-entidade="!!usuario && usuario.id ? usuario.id : 700"
      :title="$t('label.upload_anexo')"
      :url="uriUpload"
      :auto-process-queue="false"
      @UPLOADFILE_SENDING="enviandoArquivo"
      @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
      @UploadArquivo__success="uploadSucesso">
      <v-text-field
        v-model="descricaoArquivo"
        :rules="[rules.required]"
        :counter="150"
        maxlength="150"
        :label="`${$t('label.descricao')}*`"
        required
      ></v-text-field>
    </upload-file-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Confirm from '@/shared-components/dialog/Confirm';
import UploadFileForm from '@/shared-components/upload/UploadFileForm';
import basePath from '@/common/functions/api-resource';
import ListFiles from '@/shared-components/files/ListFiles';

export default {
  name: 'UsuarioFormArquivo',
  components: {
    UploadFileForm,
    ListFiles,
    Confirm,
  },
  props: {
    usuario: Object,
    isNovoCadastro: Boolean,
    habilitaAnexoFornecedor: {
      type: Boolean,
      default: false,
    },
    podeVisualizarAnexos: {
      type: Boolean,
      default: false,
    },
    podeCadastrarAnexos: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descricaoArquivo: null,
      exibeArquivos: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      planejamentoAcaoCadastroResource: this.$api.planejamentoAcaoCadastro(this.$resource),
    };
  },
  computed: {
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    uri() {
      const urlUpload = basePath('job', 'arquivo/usuario');
      return `${urlUpload}/${this.usuario.id}`;
    },
    uriUpload() {
      const urlUpload = basePath('job', 'arquivo/usuario');
      return `${urlUpload}/${this.usuario.id}/upload`;
    },
  },
  methods: {
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
    },
    fazerUpload() {
      this.$refs.uploadArquivo.open();
    },
  },
};
</script>
<style scoped>
.anexo-desabilitado {
  padding: 12px 0 0 12px;
  font-size: 16px !important;
  color: red;
}
.painel-anexo .container.mx-0 {
  max-width: 97% !important;
}
</style>
